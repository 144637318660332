import { Component, ViewChild } from '@angular/core';
import { ContestService } from '../../_services/contest.service';
import { ExamService } from '../../_services/exam.service';
import { FileUploadService } from '../../_services/fileupload.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient,HttpEventType, HttpHeaders  } from '@angular/common/http';
import { AppConstants } from '../../common/app.constants';
import { Contest } from '../../../models/contest';
import { Exam } from '../../../models/exam';
import { Organization } from '../../../models/organization';
import { Quiz } from '../../../models/quiz/quiz';
import { QuizConfig } from '../../../models/quiz/quiz-config';
import { Question } from '../../../models/quiz/question';
import { Option } from '../../../models/quiz/option';
import { ResultContestService } from '../../_services/result-contest.service';

@Component({
  templateUrl: 'contestonline-thi.component.html'
  ,styleUrls: ['contestonline-thi.css']
})
export class ContestOnlineThiComponent {

  slug: any;
  quizes: any[];
  quiz: Quiz = new Quiz(null);
  mode = 'quiz';
  quizName: string;
  config: QuizConfig = {
    'allowBack': true,
    'allowReview': true,
    'autoMove': false,  // if true, it will move to next question automatically when answered.
    'duration': 300,  // indicates the time (in secs) in which quiz needs to be completed. 0 means unlimited.
    'pageSize': 1,
    'requiredAll': false,  // indicates if you must answer all the questions before submitting.
    'richText': false,
    'shuffleQuestions': true,
    'shuffleOptions': true,
    'showClock': true,
    'showPager': true,
    'theme': 'none'
  };

  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  timer: any = null;
  startTime: Date;
  endTime: Date;
  ellapsedTime = '00:00';
  duration = '';
  resultId: any
  originBackend: any = AppConstants.API_URL2;

  constructor(private contestService: ContestService, private examService: ExamService, private route: ActivatedRoute,
    private router: Router, private resultContestService: ResultContestService){
      this.route.params.subscribe(params => {
        if(params['slug']){
          this.slug = params['slug'];
        }
      });
      if(this.router.getCurrentNavigation().extras.state === undefined){
        this.router.navigate(['/contest/'+this.slug]);
      } else {
        this.resultId = this.router.getCurrentNavigation().extras.state
      }
  }


  ngOnInit(): void {
    this.getContestBySlug(this.slug);
  }

  getContestBySlug(slug){
    this.contestService.getContestBySlugProtectPassword(slug).subscribe(
      data => {
        if(data.error){
          this.router.navigate(['/contest']);
        } else {
          this.quiz = new Quiz(data.resultData.exam);
          this.pager.count = this.quiz.examQuestion.length;
          const timeExam = data.resultData.contestSettingQuiz.contestTimeMinute;
          if(timeExam != 0){
            this.config.duration = data.resultData.contestSettingQuiz.contestTimeMinute * 60
            this.startTime = new Date();
            this.ellapsedTime = '00:00';
            this.timer = setInterval(() => { this.tick(); }, 1000);
            this.duration = this.parseTime(this.config.duration);
          }
          this.mode = 'quiz';
        }
      }, err => {
        this.router.navigate(['/contest']);
      }
    )
  }

  tick() {
    const now = new Date();
    const diff = (now.getTime() - this.startTime.getTime()) / 1000;
    if (diff >= this.config.duration) {
      clearInterval(this.timer)
      this.onSubmit();
    }
    this.ellapsedTime = this.parseTime(diff);
  }

  parseTime(totalSeconds: number) {
    let mins: string | number = Math.floor(totalSeconds / 60);
    let secs: string | number = Math.round(totalSeconds % 60);
    mins = (mins < 10 ? '0' : '') + mins;
    secs = (secs < 10 ? '0' : '') + secs;
    return mins+':'+secs;
  }

  get filteredQuestions() {
    return (this.quiz.examQuestion) ?
      this.quiz.examQuestion.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  onSelect(question: Question, option: Option) {
    if (question.questionTypeId === 1) {
      question.examAnswer.forEach((x) => { if (x.id !== option.id) x.selected = false; });
    }

    if (this.config.autoMove) {
      this.goTo(this.pager.index + 1);
    }
  }

  goTo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = 'quiz';
    }
  }

  isAnswered(question: Question) {
    return question.examAnswer.find(x => x.selected) ? 'Đã trả lời' : 'Chưa trả lời';
  };

  isCorrect(question: Question) {
    question.examAnswer.forEach(x => {
      if(x.selected === undefined){
        x.selected = false;
      }
    })
    return question.examAnswer.every(x => x.selected === x.isAnswer) ? 'correct' : 'wrong';
  };

  onSubmit() {
    let answers = [];
    this.quiz.examQuestion.forEach(x => answers.push({ 'quizId': this.quiz.id, 'questionId': x.id, 'answered': x.answered }));
    this.quiz.examQuestion.forEach(eQ => {
      eQ.examAnswer.forEach(eA => {
        if(eA.selected === undefined){
          eA.selected = false;
        }
      })
    })
    clearInterval(this.timer)
    // Post your data to the server here. answers contains the questionId and the users' answer.
    this.resultContestService.updateDataResultContest(this.resultId, this.quiz).subscribe(
      data => {
        this.router.navigateByUrl('/contest/'+this.slug + '?resultId='+this.resultId)
      }, err => {
      }
    )
    // this.mode = 'result';
  }
}
