export class Option {
    id: number;
    examQuestionId: number;
    content: string;
    isAnswer: boolean;
    selected: boolean;

    constructor(data: any) {
        data = data || {};
        this.id = data.id;
        this.examQuestionId = data.examQuestionId;
        this.content = data.content;
        this.isAnswer = data.rightWrong === 'RIGHT' ? true : false ;
        this.selected = false;
    }
}