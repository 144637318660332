import { Option } from './option';

export class Question {
    id: number;
    content: string;
    questionTypeId: number;
    examAnswer: Option[];
    answered: boolean;
    explanation: string;
    mediaType: string;
    mediaResource: string;
    mediaImage: string;

    constructor(data: any) {
        data = data || {};
        this.id = data.id;
        this.content = data.content;
        this.explanation = data.explanation;
        this.mediaType = data.mediaType;
        this.mediaResource = data.mediaResource;
        this.mediaImage = data.mediaImage;
        this.questionTypeId = data.typeOfQuestion === 'SINGLE' ? 1: 0;
        this.examAnswer = [];
        data.examAnswer.forEach(o => {
            this.examAnswer.push(new Option(o));
        });
    }
}