import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppConstants } from '../../common/app.constants';
import { navItems, navItemsAdmin } from '../../_nav';
import { ContestService } from '../../_services/contest.service';
import { TokenStorageService } from '../../_services/token-storage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {

  public sidebarMinimized = false;
  public navItems = navItems;
  isLoggedIn = false;
  username: string;
  avatar: string;
  codeContest = '';
  roles: string[];
  showHuyenTPBoard = false;
  showAdminBoard = false;
  showModBoard = false;
  
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  constructor(private tokenStorageService: TokenStorageService, private router: Router,
    private contestService: ContestService) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.username = user.displayName;
      this.avatar = AppConstants.API_URL2 + user.avatar;
      this.roles = user.roles;
      this.showModBoard = this.roles.includes('ROLE_MODERATOR');
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
    }
    let desktopRole = localStorage.getItem('desktopRole')
    if (desktopRole === 'admin') {
      this.navItems = navItemsAdmin;
    }
  }

  onSubmit() {
    if (this.codeContest) {
      this.contestService.getSlugByCode(this.codeContest).subscribe(
        data => {
          if (!data.error) {
            this.router.navigateByUrl('/contest/' + data.resultData)
          } else {
          }
        }, err => {
        }
      )
    }
  }

  changeRolesMod() {
    localStorage.setItem('desktopRole', 'mod')
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    let currentUrl = this.router.url + '?';

    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate(['/tin-tuc']);
      });
  }

  changeRolesAdmin() {
    localStorage.setItem('desktopRole', 'admin')
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    let currentUrl = this.router.url + '?';

    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate(['/admin/tin-tuc']);
      });
  }

  logout(): void {
    this.tokenStorageService.signOut();
    this.router.navigate(['/login'])
  }
}
