import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { ContestOnlineThiComponent } from './views/contestonline/contestonline-thi.component';
import { ContestOnlineViewComponent } from './views/contestonline/contestonline-view.component';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'tin-tuc',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'tai-khoan',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'de-thi',
        loadChildren: () => import('./views/desktop-huyentp/exam/exam.module').then(m => m.ExamModule)
      },
      {
        path: 'tai-lieu',
        loadChildren: () => import('./views/desktop-huyentp/thuvien/thuvien/thuvien.module').then(m => m.ThuVienModule)
      },
      {
        path: 'tin-tuc',
        loadChildren: () => import('./views/desktop-huyentp/news/news.module').then(m => m.NewsHuyenTPModule)
      },
      {
        path: 'admin/tin-tuc',
        loadChildren: () => import('./views/desktop-huyentp/admin-news/admin-news.module').then(m => m.AdminNewsModule)
      },
      {
        path: 'chuong-trinh',
        loadChildren: () => import('./views/desktop-huyentp/chuong-trinh/chuong-trinh.module').then(m => m.ChuongTrinhModule)
      },
      {
        path: 'daibieu-thamgia',
        loadChildren: () => import('./views/desktop-huyentp/daibieuthamgia/dsdaibieu.module').then(m => m.DsdaibieuModule)
      },
      {
        path: 'so-do-vi-tri',
        loadChildren: () => import('./views/desktop-huyentp/location-map/location-map.module').then(m => m.LocationMapModule)
      },
      {
        path: 'permission',
        loadChildren: () => import('./views/desktop-huyentp/permission/permission.module').then(m => m.PermissionModule)
      },
      {
        path: 'lich-su',
        loadChildren: () => import('./views/history/history.module').then(m => m.HistoryModule)
      },
      {
        path: 'dai-bieu',
        loadChildren: () => import('./views/desktop-huyentp/addUser/addUser.module').then(m => m.AddUserModule)
      },
      {
        path: 'thanh-vien',
        loadChildren: () => import('./views/desktop-huyentp/member/member.module').then(m => m.MemberModule)
      },
      {
        path: 'banner',
        loadChildren: () => import('./views/desktop-huyentp/banner/banner.module').then(m => m.BannerModule)
      },
      {
        path: 'voting',
        loadChildren: () => import('./views/desktop-huyentp/voting/voting.module').then(m => m.VotingModule)
      },
      {
        path: 'results',
        loadChildren: () => import('./views/desktop-huyentp/results/results.module').then(m => m.ResultsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
