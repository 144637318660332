import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/app.constants';

const httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};


@Injectable({
  providedIn: 'root'
})
export class ResultContestService {

  constructor(private http: HttpClient) { }

  checkPassword(password,slug): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'resultContest/checkPassowrd/' + slug, password ,httpOptions);
  }

  addResultContest(data): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'resultContest/add',data ,httpOptions);
  }

  updateResultContest(data): Observable<any> {
    return this.http.put(AppConstants.API_URL + 'resultContest/update',data ,httpOptions);
  }

  updateDataResultContest(resultId, data): Observable<any> {
    return this.http.put(AppConstants.API_URL + 'resultContest/updateData/'+resultId, data ,httpOptions);
  }

  getResultContestById(id): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'resultContest/' + id, httpOptions);
  }

  getResultContestByIdOfCorrectNumber(id): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'resultContest/' + id + '/correctNumber', httpOptions);
  }

  getResultContestByContestId(id,page): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'resultContest/byContestId/' + id + '?page='+ page +'&size=10', httpOptions);
  }

  getResultContestByCurrentUser(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'resultContest/byCurrentUser?search='+ search +'&page='+ page +'&size='+size, httpOptions);
  }
  
  getCurrentUser(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'user/me', httpOptions);
  }

  deleteResultContest(data): Observable<any> {
    return this.http.put(AppConstants.API_URL + 'resultContest/delete',data, httpOptions);
  }
}
