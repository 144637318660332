import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Tài khoản',
    url: 'tai-khoan',
    icon: 'fa fa-user'
  },
  {
    name: 'Tin tức',
    url: 'tin-tuc',
    icon: 'bi bi-newspaper'
  },
  {
    name: 'Tài liệu',
    url: 'tai-lieu',
    icon: 'fa fa-file'
  },
  {
    name: 'Đề thi',
    url: 'de-thi',
    icon: 'bi bi-journal-text'
  },
  {
    name: 'Đại biểu',
    url: 'dai-bieu',
    icon: 'fa fa-group'
  },
  {
    name: 'Chương trình',
    url: 'chuong-trinh',
    icon: 'fa fa-calendar-check-o'
  },
  {
    name: 'Đại biểu tham gia',
    url: 'daibieu-thamgia',
    icon: 'fa fa-check-square-o'
  },
  {
    name: 'Sơ đồ vị trí',
    url: 'so-do-vi-tri',
    icon: 'fa fa-address-card-o'
  },
  {
    name: 'Biểu quyết',
    url: 'voting',
    icon: 'fa fa-thumbs-up'
  },
  {
    name: 'Kết quả đại hội',
    url: 'results',
    icon: 'fa fa-vcard-o'
  }
  // {
  //   name: 'Lịch sử',
  //   url: 'lich-su',
  //   icon: 'fa fa-history'
  // }
];

export const navItemsAdmin: INavData[] = [
  // {
  //   name: 'Tài khoản',
  //   url: 'tai-khoan',
  //   icon: 'fa fa-user'
  // },
  {
    name: 'Tin tức',
    url: 'admin/tin-tuc',
    icon: 'bi bi-newspaper'
  },
  {
    name: 'Banner',
    url: 'banner',
    icon: 'bi bi-card-image'
  },
  {
    name: 'Thành viên',
    url: 'thanh-vien',
    icon: 'fa fa-group'
  }
];